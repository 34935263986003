import React from 'react';
import './styles/Footer.css';
import CallIcon from '@material-ui/icons/Call';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import EmailIcon from '@material-ui/icons/Email';
import TwitterIcon from '@material-ui/icons/Twitter';
import DnsIcon from '@material-ui/icons/Dns';
import {Link} from 'react-router-dom';


function Footer() {
    return (
        <div className="footer-main">
            <div className='head-contac_phone_email'>
                <div style={{display:'flex',alignItems:'center'}}>
                    <DnsIcon className='footer-social_networks' style={{padding:'0 10px'}}/>
                    <h4 className='head-colorized header-social_contact'>498 William Floyd Pkwy Shirley, NY 11967 United States</h4>
                </div>
                <div style={{display:'flex',alignItems:'center'}}>
                    <CallIcon className='footer-social_networks' style={{padding:'0 10px',fontSize:'24px'}}/>
                    <h4
                        style={{cursor: 'pointer'}}
                        onClick={()=>window.open("tel:+1-934-852-0025")}
                        className='head-colorized header-social_contact'>+1 (934) 852-0025
                    </h4>
                </div>
                <div style={{display:'flex',alignItems:'center'}}>
                    <EmailIcon className='footer-social_networks' style={{padding:'0 10px'}}/>
                    <h4 onClick={()=>window.open('mailto:omega.qualityinc@gmail.com?subject=Contacting%20Omega&body=')} className='head-colorized header-social_contact'>Omega.qualityinc@gmail.com</h4>
                </div>
            </div>


            <p style={{color:'white',fontSize:'18px',padding:'0 30px'}}>© Copyright 2021 OmegaQualityInc.com All rights reserved.</p>
            <div>
                <Link target="_blank" to={"//www.facebook.com/omegaqualityinc"} >
                    <FacebookIcon className='footer-social_networks'/>
                </Link>
                <Link target="_blank" to={"//www.instagram.com/omegaqualityinc/"} >
                    <InstagramIcon className='footer-social_networks'/>
                </Link>
                <Link target="_blank" to={"//www.twitter.com/qualityomega"} >
                    <TwitterIcon className='footer-social_networks'/>
                </Link>
            </div>
        </div>
    )
}

export default Footer;
