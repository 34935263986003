import firebase from 'firebase';

var firebaseConfig = {
  apiKey: "AIzaSyCkge5XUAgRBLlaHIo25tmJVEf36HrYjOQ",
  authDomain: "omegaquality-official.firebaseapp.com",
  databaseURL: "https://omegaquality-official-default-rtdb.firebaseio.com",
  projectId: "omegaquality-official",
  storageBucket: "omegaquality-official.appspot.com",
  messagingSenderId: "763512709811",
  appId: "1:763512709811:web:2e2cc87aed4179f84186c6",
  measurementId: "G-C9H6EP9M6S"
  };

  firebase.initializeApp(firebaseConfig);


  export default firebase.database();