import React from 'react';
import './styles/Services.css';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {Button} from '@material-ui/core';
import {Link} from 'react-router-dom';


function Services({myRef}) {
    return (
        <div ref={myRef} className="services-main">
            <h1 style={{color:'#1E8449',textAlign:'center',fontSize:'50px'}}>What We Offer?</h1>
            
           <div className="services-item">
               <img className="services-item_image" src={'https://firebasestorage.googleapis.com/v0/b/omegaquality-official.appspot.com/o/roofing%2FWhatsApp%20Image%202021-06-11%20at%2011.21.21%20PM.jpeg?alt=media&token=7f511d5a-9b6b-436d-8ede-884e8dce0420'}/>
            <div className="services-item_content">
               <div className="service-product_title">
                <ArrowForwardIosIcon className='service-colorized'/>
                <h3 className='service-colorized'>Roofing/Chimney and repairments</h3>
                </div>
                <p className='service-colorized'>
                When it comes to roof, it is quality of installation and material
                that is most important.As the professional...    
                </p>
                    <Link to='/item-details/roofing' style={{textDecoration:'none',color:'black'}}>
                    <Button style={{padding:"5px 20px",backgroundColor:'#3498DB',fontWeight: 'bold'}} className="services-learnmore_btn">
                   Learn More
                   </Button>    
                   </Link> 
            </div>
           </div> 


           <div className="services-item_right">
            <div className="services-item_content_right">
               <div className="service-product_title service-right">
                   <ArrowForwardIosIcon className='service-colorized'/>
                <h3 className='service-colorized'>Siding</h3>
                </div>
                <p className='service-colorized'>
                We are a small group of roofing, skylight, siding and gutters workers.
                We can help you with home exterior...
                </p>
                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                    <Link to='/item-details/siding' style={{textDecoration:'none',color:'black'}}>
                    <Button style={{padding:"5px 20px",backgroundColor:'#3498DB',fontWeight: 'bold'}} className="services-learnmore_btn">
                   Learn More
                  </Button> 
                  </Link>
                  </div>    
            </div>
            <img className="services-item_image" src={'https://firebasestorage.googleapis.com/v0/b/omegaquality-official.appspot.com/o/siding%2F5.jpg?alt=media&token=2f6fb9d2-6fc1-4a1f-8fc5-9ce1ca67e802'}/>
           </div> 

           
           <div className="services-item">
               <img className="services-item_image" src={'https://firebasestorage.googleapis.com/v0/b/omegaquality-official.appspot.com/o/gutter%2F3.jpg?alt=media&token=8fbb1cec-3050-477e-9a64-2ff99ccb1a06'}/>
            <div className="services-item_content">
               <div className="service-product_title">
                <ArrowForwardIosIcon className='service-colorized'/>
                <h3 className='service-colorized'>Gutter Installation Long Island</h3>
                </div>
                <p className='service-colorized'> 
                There are times when the gutter ends up leaking or cracking.This can happen due
                to so many reasons.
                </p>
                <Link to='/item-details/gutters' style={{textDecoration:'none',color:'black'}}>
                    <Button style={{padding:"5px 20px",backgroundColor:'#3498DB',fontWeight: 'bold'}} className="services-learnmore_btn">
                   Learn More
                   </Button>   
                   </Link>  
            </div>
           </div> 


           <div className="services-item_right">
            <div className="services-item_content_right">
               <div className="service-product_title  service-right">
                   <ArrowForwardIosIcon className='service-colorized'/>
                <h3 className='service-colorized'>Skylight Installation Long Island</h3>
                </div>
                <p className='service-colorized'>
                For more than 10 years we have been installing and servicing skylights in the New York.
                We have an experienced...
               </p>
                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                    <Link to='/item-details/skylight' style={{textDecoration:'none',color:'black'}}>
                    <Button style={{padding:"5px 20px",backgroundColor:'#3498DB',fontWeight: 'bold'}} className="services-learnmore_btn">
                   Learn More
                  </Button> 
                  </Link>
                  </div>    
            </div>
            <img className="services-item_image" src={'https://firebasestorage.googleapis.com/v0/b/omegaquality-official.appspot.com/o/skylight%2F1f68bd46-e149-4539-b97a-61c9ce89ac56.jpg?alt=media&token=fb4b2fdb-2cbc-4048-8277-963ab1c565d6'}/>
           </div> 


        </div>
    )
}

export default Services;
