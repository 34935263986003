import React, {useState} from 'react';
import './styles/ContactUs.css';
import emailjs from 'emailjs-com';

function ContactUs({myRef}) {
    const [name,setName]=useState("");
    const [email,setEmail]=useState("");
    const [phone,setPhone]=useState("");
    const [messagedata,setMessagedata]=useState("");

    function sendEmail(e) {
        e.preventDefault();
       
       if(name && (email || phone) && messagedata){
        emailjs.sendForm('service_dmc2imf', 'template_kv1lvgz', e.target, 'user_79MHaKQjXutF9E0ihM5Yg')
        .then((result) => {
            console.log(result.text);
            setName("");
            setEmail("");
            setPhone("");
            setMessagedata("");
            alert("We recieved your message. We will contact you soon. Thank you!");
        }, (error) => {
            alert("We cound not recieve your message, please try again!");
            console.log(error.text);
        });
       }else{
           alert("Please fill those fields: e-mail or phone, your name and a message");
       }
      }


    return (
        <div ref={myRef} className="contactus-main">
                <div style={{width:'100%',height:'1px',backgroundColor:'grey'}}/>
            <div className="contactus-container">
                <h1
                style={{fontSize:'50px'}}
                >Contact Us</h1>
                <form onSubmit={sendEmail}>
                <div className="contactus-field_container">

                <label className="aboutus-contact_form_label">
                Full Name:
                <input 
                onChange={(text)=>setName(text.target.value)}
                value={name} className='contactus-input' name="name" type='text' placeholder="name"/>
                </label>
                <br/>

                <label className="aboutus-contact_form_label">
                E-mail:
                <input
                onChange={(text)=>setEmail(text.target.value)}
                value={email}  className='contactus-input' type='email' name="email" placeholder="e-mail"/>
                </label>
                <br/>

                <label className="aboutus-contact_form_label">
                Phone Number:
                <input 
                onChange={(text)=>setPhone(text.target.value)}
                value={phone} name="phone" className='contactus-input' type='tel' placeholder="phone number"/>
                </label>
                <br/>

                <label className='contactus-input' className="aboutus-contact_form_label">
                    Message:
                <textarea 
                onChange={(text)=>setMessagedata(text.target.value)}
                value={messagedata} name="message" rows="4" placeholder="your message..." className='contactus-input contactus-message'
                 />
                </label>

                <input
                type="submit"
                className="contactus-button"
                />  

                </div>
                </form>
            </div>
            
            <div style={{width:'100%',height:'1px',backgroundColor:'grey',marginTop:'30px'}}/>
        </div>
    )
}

export default ContactUs;
