import React, {useState} from 'react';
import './styles/AboutUsPage.css'
import HeadContact from './HeadContact';
import CompServices from './CompServices';
import OurLocation from './OurLocation';
import {motion} from 'framer-motion';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Close from '@material-ui/icons/Close';
import Footer from './Footer';
import {Link} from 'react-router-dom';
import ContactUs from './ContactUs';



const AboutUsPage = () => {
    const [contact, setContact] = useState(false);
    return (
        <div style={{ scrollBehavior: 'auto'}}>
            <HeadContact/>
            <Link to='/' style={{textDecoration:'none',color:'black'}}>
            <h3 style={{cursor:'pointer',display:'inline-flex',alignItems:'center'}}><ArrowBackIcon/>&nbsp;&nbsp;Go Back To Home</h3>
            </Link>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'300px'}}>
            <img className="aboutuscontact-image" src={"https://images.unsplash.com/photo-1563257764-ec4bd2983cbe?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"}/>
            <h1 style={{position:'absolute',color:'white',fontSize:60}}>About Us</h1>
            </div>

            <div className="aboutuspage-contactus">
                <div className="aboutuspage-contactus-text" >
                    <h1>Who we are?</h1>
                <p>Omega Quality Inc Inc is a Long Island Roofing and Chimney installation 
                    company with over 10 years of experience in roofing, chimney, siding and gutters 
                    installation and repairing services. A company with an abundance of knowledge and 
                    experience in the roofing and chimney installation industry using more than 10 years 
                    of expertise provides the dependability you look for when you initially trust your 
                    come together.
                    Omega Quality Inc Inc is a fully licensed and insured Long Island roofing and
                    chimney installation company that operates in and around Suffolk County. Our business
                    is family owned and operated and we have over 10 years. We try to give roof top structure
                    professional providers no matter what it becoming a very small restoration or perhaps a full
                    alternative or putting in.</p>
                    </div>
                    {contact && 
                    <div 
                    className='contact-aboutus'
                    style={{
                     display:'flex',
                     alignItems:'center',
                     justifyContent:'center',
                     flexDirection:'column',
                     position:'absolute',
                     padding:'0 10px',
                     backgroundColor:'rgba(211, 84, 0, 0.95)'}}>
                         <Close onClick={()=>setContact(false)} style={{color:'white', padding:10,cursor:'pointer'}}/>
                     <ContactUs/>
                    </div>}
                <div className="aboutuspage-contactus-thebutton">
                    <h1 style={{textAlign:'center'}}>Want to talk with us?</h1>
                    <motion.div
                    onClick={()=>setContact(true)}
                    whileHover={{
                        color:'#F1C40F',
                        borderColor:'#F1C40F',
                        backgroundColor:'rgba(0,0,0,0.6)'
                    }}
                    style={{cursor:'pointer',border:'1px solid white',width:'50%',alignSelf:'center'}}>
                        <h4 style={{textAlign:'center',margin:15}}>Contact Us</h4>
                    </motion.div>
                </div>
            </div>
        <CompServices/>
        <OurLocation/>
        <Footer/>
        </div>
    )
}

export default AboutUsPage;
