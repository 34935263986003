import React, { useState } from 'react';
import './styles/OurLocation.css';
import ReactMapGL, {Marker} from 'react-map-gl';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const OurLocation = ({myRef}) => {

  const [viewport, setViewport] = useState({
    width: '100%',
    height: '50vh',
    latitude: 40.71427,
    longitude: -74.00597,
    zoom: 6
  });

  return (
      <>
        <h1 ref={myRef} style={{textAlign:'center'}}>Where Can You Find Us?</h1>

        <div className="our-location_main" style={{display:'flex'}}>
          <div className="our-location-strech strech1">
            <h1>Those are the locations we Service:</h1>
            <ul>
              <li  className="location-place" style={{color:'#F1C40F'}}><ArrowForwardIosIcon/><b>Long Island (all)</b></li>
              <li className="location-place"><ArrowForwardIosIcon/><b>Suffolk County</b></li>
              <li className="location-place"><ArrowForwardIosIcon/><b>Nassau Country</b></li>
              <li style={{textAlign:"center"}} className="location-place"><b>etc...</b></li>
            </ul>
          </div>

          <div  className="our-location-strech strech2" style={{marginBottom:'60px'}}>
            <ReactMapGL  {...viewport}
                         mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
                         mapboxApiAccessToken={"pk.eyJ1Ijoib2xzaWhveGhhOCIsImEiOiJja2puaWNoNGwwM3NtMnlsMXhpYzNkczl4In0.fUozUh7b6zcs5tukFoVtiw"}
                         onViewportChange={setViewport}>


              <Marker
                  latitude={40.792040}
                  longitude={ -72.865393}
              >
                <div>
                  <svg
                      className="marker"
                      style={{
                        height: `${6 * viewport.zoom}px`,
                        width: `${6 * viewport.zoom}px`,
                        fill:'red'
                      }}
                      version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512"
                  >
                    <g>
                      <g>
                        <path d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173.004,321.035
                        c6.636,7.391,18.222,7.378,24.846,0c7.065-7.868,173.004-194.147,173.004-321.035C441.425,83.182,358.244,0,256,0z M256,278.719
                        c-51.442,0-93.292-41.851-93.292-93.293S204.559,92.134,256,92.134s93.291,41.851,93.291,93.293S307.441,278.719,256,278.719z"/>
                      </g>
                    </g>
                  </svg>
                </div>
              </Marker>





            </ReactMapGL>
          </div>

        </div>
      </>
  )
}

export default OurLocation;
