import React from 'react';
import './styles/Head.css';
import CallIcon from '@material-ui/icons/Call';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import EmailIcon from '@material-ui/icons/Email';
import TwitterIcon from '@material-ui/icons/Twitter';
import DnsIcon from '@material-ui/icons/Dns';
import {Link} from 'react-router-dom';


function HeadContact(){
    return(
        <div className='head-contact'>
        <div className='head-contac_phone_email'>
        <div style={{display:'flex',alignItems:'center'}}>
        <DnsIcon className='head-colorized-icon' style={{padding:'0 10px',fontSize:'24px'}}/>
        <h4 className='head-colorized'>498 William Floyd Pkwy Shirley,<br></br>NY  11967 United States</h4>
        </div>
        <div style={{display:'flex',alignItems:'center'}}>
        <CallIcon className='head-colorized-icon' style={{padding:'0 10px',fontSize:'24px'}}/>
        <h4
        style={{cursor: 'pointer'}}
        onClick={()=>window.open("tel:+1-934-852-0025")} className='head-colorized header-social_contact'>+1 (934) 852-0025</h4>
        </div>
        <div style={{display:'flex',alignItems:'center'}}>
        <EmailIcon className='head-colorized-icon' style={{padding:'0 10px',fontSize:'24px'}}/>
        <h4 onClick={()=>window.open('mailto:omega.qualityinc@gmail.com?subject=Contacting%20Omega&body=')} className='head-colorized header-social_contact'>
            Omega.qualityinc@gmail.com</h4>
        </div>
        </div>
        <div className="head-social_networks">
        <h4 className='head-colorized'>Contact Us:</h4>
        <Link target="_blank" to={"//www.facebook.com/omegaqualityinc"} >

        <FacebookIcon className='head-colorized-icon header-social_contact'
        style={{padding:'5px',fontSize:'30px'}}/>

  
        </Link> 

        <Link target="_blank" to={"//www.instagram.com/omegaqualityinc/"} >
        <InstagramIcon className='head-colorized-icon header-social_contact' 
        style={{fontSize:'30px'}}/>
        </Link>
        
        <Link target="_blank" to={"//www.twitter.com/qualityomega"}>
        <TwitterIcon className='head-colorized-icon header-social_contact' 
        style={{padding:'5px',fontSize:'30px'}}/>
        </Link>
        </div>
        </div>
    )
}


export default HeadContact;