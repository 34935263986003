import React, { useState,useEffect, useRef} from 'react';
import './styles/ItemDetails.css';
import HeadContact from './HeadContact';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import {Link, useParams} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import Footer from './Footer';
import service24h from '../images/service24h.png';
import affordable from '../images/affordable.png';
import {motion} from 'framer-motion';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import database from '../configs/firebaseConfig';



const items={siding:[
            ["Siding Installation","Vinyl Siding","Fascia Covers","Stone Siding","Soffit Repair"],
            ["Siding Repair","Cement Fiber Siding","Fascia Boards","Brick Siding","Wood Clapboard Siding"],
            ["Siding Replacement","Aluminum Siding","Plastic Siding","Stucco Siding","Cedar Shake Siding"],
            ["Siding Cleaning","Fascia Repair","Seamless Steel Siding","Vented Soffit","Engineered Wood Siding"]
            ],
            roofing:[
            ['Roof Repair','Roof Installation','Commercial Roofing','GACORoof Coating','Roof Vent Repair','Flat Roof Replacement','Roll Down Roofing','Metal Roofing','Firestone Roofing'],
            ['Roof Leak Repair','Roof Construction','Roof Inspections','Roof Tarp Installation','Flat Roofing','Torch Down Roofing','PVC Roofing','Tile Roofing','JM Roofing'],
            ['Roof Replacement','Roof Maintenance','Roof Waterproofing','Roof Flashing','Flat Roof Repair','EPDM Flat Roofing','TPO Roofing','Cedar Shake Roofing','Certainteed Roofing'],
            ['Roof Framing','Residential Roofing','Roof Caulking','Roof Fascia Repair','Flat Roof Leak Repair','Peel and Stick Roofing','Shingle Roofing','GAF Roofing','Tamko Roofing']
            ],
            gutters:[
            ['Gutter Repair','Gutter Installation','Seamless Gutters'],
            ['Gutter Leak Repair','Gutter Replacement','Copper Gutters'],
            ['Gutter Cleaning','Downspout Installation','Gutter Screens'],
            ['Gutter Maintenance','Downspout Repair','and more']
            ],
            skylight:[
              ['Skylight Installation'],
              ['Skylight Repair'],
              ['Skylight Replacement'],
              ['Skylight Leak Repair']
            ]
          };

const titles={
  siding:'Siding',
  roofing:'Roofing/Chimney',
  gutters:'Gutter Installation Long Island',
  skylight:'Skylight Installation Long Island'
}


const mainText={
  roofing:{
    title:'Roofing Installation/Chimney and repairments',
    text:'At Omega Quality Inc, our local roofers are here to help for all of your roofing needs from installation, to maintenance, and repair. When it comes to your roof, it is the quality of the installation and materials used that are most important. As leading roofing contractors on long island, the experts at Omega Quality Inc are committed to give you quality roofing services with guaranteed customer satisfaction.Affordable Roofing Services.Our local roofing experts provide accurate and affordable estimates.Our Long Island Roof Installation experts are highly trained and in the flawless installation of all different types of roofs. We assure you that the roofs installed by us will be top quality. With vast knowledge and experience in the industry on countless projects, our team will help you make the right choices for your project. We have been helping families in all aspects of their remodeling, roof installation on Long Island, roof repairs, roof inspection, shingle roofing, and flat roofing. We can help you fix unexpected damages and unscheduled breakdowns. From roof installation to roof repair, experts at Omega Quality Inc can help any way you want.'
  },
  siding:{
    title:'Over 10 Years Of Expertise In Long Island',
    text:'We are small group of roofing, siding and gutters workers. We can help you with home exterior problems and any other service related to it.When siding is installed and maintained correctly, it can hold up for 30 or 40 years. But without proper attention, isolated areas can begin rotting in only a few years, especially near the foundation. Water splashes up from the ground, frequently soaking the vulnerable bottom edges. The paper face then flakes off, exposing the dark brown inner layers, and each soaking accelerates the rotting. Don’t let the fact that you have siding deter you from that repair or remodeling project. If you want to shift a window, replace a door or even put on an addition, we can remove, alter and reinstall your siding.' 
  },
  gutters:{
    title:'Long Island Gutter Installations, Repairs And Cleaning',
    text:'There are times when the gutter ends up leaking or cracking. This can happen due to so many reasons. Don’t fret! The experts at Omega Quality Inc can help you get your gutter back in the working condition. It is extremely necessary to have functioning gutter. A well working gutter makes sure that the water does not damage the foundation or flood the backyard. Out team of experts will ensure that your home remains in the pristine condition. We are also able to access the high-up locations that would be difficult for you to reach or repair.At Omega Quality Inc, we will provide you most effective and affordable gutter repair service. We ensure that you don’t have to repair whole gutter and there would be no future issues with the gutter system. If you are facing any problems with your gutter system, call Omega Quality Inc. Our company has been providing Long Island Gutter Repairs for over 10 years of experience in roofing, siding and gutters installation and repairing services.'
  },
  skylight:{
    title:'Long Island Skylight Installation',
    text:'For more than 10 years we have been installing and servicing skylights in the New York. We have an experienced team of skylight contractors on Long Island that not only renders quality work but offers quality service and customer satisfaction. Till now, we have replaced numerous skylights. We recommend replacing your skylights when you re-roof. It’s the most cost-efficient way, and it enables you to synchronize your roof and skylight warranties. We care for your investment and of course your home and business. Contact us today for a free consultation. Give us a call to schedule your FREE estimate!'
  }
}



              const galleryImages=["https://images.unsplash.com/photo-1565008447742-97f6f38c985c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1489&q=80",
              "https://images.unsplash.com/photo-1512917774080-9991f1c4c750?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mnx8aG9tZXxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
              "https://images.unsplash.com/photo-1517089596392-fb9a9033e05b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1268&q=80",
              "https://images.unsplash.com/photo-1512207736890-6ffed8a84e8d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1358&q=80",
              "https://images.unsplash.com/photo-1512917774080-9991f1c4c750?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mnx8aG9tZXxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
              "https://images.unsplash.com/photo-1517089596392-fb9a9033e05b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1268&q=80",
              "https://images.unsplash.com/photo-1512207736890-6ffed8a84e8d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1358&q=80"];


const ItemDetails = () => {


  const [imageIndex,setImageIndex]=useState(0);
  const [images,setImages]=useState({});
  const [imagesGallery,setImagesGallery]=useState({});

  const {id}=useParams();


  const scrollRef=useRef(null);


  useEffect(()=>{
    database.ref(id.toLowerCase()+'gallery').once('value',(snapshot)=>{
      setImagesGallery(snapshot.val());
    });
  },[]);


  useEffect(()=>{
      database.ref(id.toLowerCase()).once('value',(snapshot)=>{
        setImages(snapshot.val());
      });
  },[]);


  useEffect(()=>{
    if(Object.keys(images).length){
      const interval = setTimeout(_ => {
        setImageIndex(index=>(index+1)%Object.keys(images).length);
      }, 3000);
      return _ => clearTimeout(interval);
    }
   
  },[imageIndex,Object.keys(images).length]);



    return (
        <div className="itemdetails-main">
            <HeadContact/>
            <Link to='/' style={{textDecoration:'none',color:'black'}}>
            <h3 style={{cursor:'pointer',display:'inline-flex',alignItems:'center'}}><ArrowBackIcon/>&nbsp;&nbsp;Go Back To Home</h3>
            </Link>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'300px'}}>
            <img className="aboutuscontact-image" src={"https://images.unsplash.com/photo-1534085897953-27d90056705a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"}/>   
            <h1 style={{position:'absolute',color:'white',fontSize:40}}>{titles[id.toLowerCase()]}</h1>
            </div>

            <div className='itemDetails-description' style={{display:'flex',margin:'40px'}}>
                <div style={{flex:0.6}}>
                    <h1>{mainText[id.toLowerCase()]['title']}</h1>
                    <p>
                    {mainText[id.toLowerCase()]['text']}
                    </p>
                </div>
              <div className='itemdetails-imageStack-container'>
              {Object.keys(images).map((image,index)=>{
                if(index===imageIndex){
                  return(
                    <motion.img
                    initial={{x:200}}
                    animate={{x:0}}
                    className='itemdetails-imageStack' src={images[image]['image']}/> 
                  );
                } 
              })}
              </div>
              </div>
            <div style={{flex:1,width:'100%',height:'1px',backgroundColor:'grey'}}/>
            <h1 style={{textAlign:'center'}}>Gallery</h1>
            <div style={{display:'flex',alignItems:'center',flex:1,}}>
              <ArrowBackIcon onClick={()=>{scrollRef.current.scrollBy({left:-200,top:0,behavior: 'smooth'})}}
              style={{cursor:'pointer',color:'white',backgroundColor:'rgba(0,0,0,0.6)',borderRadius:'50%',padding:5}}/>
           <div ref={scrollRef} className='itemdetails-gallery'>      
                {Object.keys(imagesGallery).map(image=>{
                  return(
                    <img src={imagesGallery[image]['image']} />
                  );
                })}
           </div>
           <ArrowForwardIcon onClick={()=>{scrollRef.current.scrollBy({left:200,top:0,behavior: 'smooth'})}}
            style={{color:'white',cursor:'pointer',backgroundColor:'rgba(0,0,0,0.6)',borderRadius:'50%',padding:5}}/>
           </div>
            <div style={{width:'100%',height:'1px',backgroundColor:'grey'}}/>
            <div style={{backgroundColor:'rgba(211, 84, 0,0.6)'}}>
                <h1 style={{textAlign:'center',margin:0,paddingTop:30}}>We proudly offer the following {id.charAt(0).toUpperCase() + id.slice(1)} Services:</h1>
                <div className="itemdetails_itemGrid" >
                    {items[id.toLowerCase()].map(item=>{
                            return(<div>
                                {item.map(itemText=> <h4 style={{
                                display:'flex',
                                alignItems:'center',
                                }}><DoubleArrowIcon style={{fontSize:12,color:'#7E5109'}}/>{itemText}</h4>)}
                                </div>)
                        })}
                    
                
                </div>
            </div>
            <div style={{width:'100%',height:'1px',backgroundColor:'grey'}}/>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',padding:'30px 0'}}>
            <h1 style={{textAlign:'center'}}>Other Services</h1>
            <SwipeableTextMobileStepper/>
            </div>

            <div className="cservices-desc_container" style={{display:'flex',justifyContent:'center',margin:'70px'}}>
           <div className='cservices-desc'>
               <img src={service24h}/>
               <h2 style={{color:' #F1C40F'}}>24/7 availability</h2>
               <p>Whеnever you can call us, wе аrе соmmіttеd tо customer satisfaction wіth quality аrtіѕtrу</p>
           </div>

           <div className='cservices-desc'>
               <img src={affordable}/>
               <h2 style={{color:' #F1C40F'}}>Affordable prices</h2>
               <p>We will hеlр fіx a leak before it bесоmеѕ a disaster. We саn hеlр уоu mаkе thе nесеѕѕаrу rераіr аll wіthіn your budgеt.</p>
           </div>

           </div>

            <Footer/>
        </div>
    )
}


//////////////////
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
  {
    label: 'Roofing/Chimney and repair',
    imgPath:
      'https://firebasestorage.googleapis.com/v0/b/omegaquality-official.appspot.com/o/roofing%2Ffirst.jpg?alt=media&token=db041834-6027-4fda-959e-5c8d77b64a78',
  },
  {
    label: 'Siding',
    imgPath:
      'https://fhrconstruction-inc.com/wp-content/uploads/2015/03/siding.jpg',
  },
  {
    label: 'Gutter Installation Long Island',
    imgPath:
      'https://www.millenniumlocalmarketing.com/wp-content/uploads/2018/06/seo-marketing-company-for-gutters-installation-contractors-1.jpg',
  },
  {
    label: 'SkyLight Installation',
    imgPath:
      'https://firebasestorage.googleapis.com/v0/b/omegaquality-official.appspot.com/o/skylight%2F1f68bd46-e149-4539-b97a-61c9ce89ac56.jpg?alt=media&token=fb4b2fdb-2cbc-4048-8277-963ab1c565d6',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '80%',
    flexGrow: 1,
    boxShadow:'-2px 4px 27px -7px rgba(0,0,0,0.3)'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 100,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    objectFit:'cover',
    height:400
  },
}));

function SwipeableTextMobileStepper() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      <Paper square elevation={0} className={classes.header}>
        <h3>{tutorialSteps[activeStep].label}</h3>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {tutorialSteps.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img className={classes.img} src={step.imgPath} alt={step.label} />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
    </div>
  );
}
/////////////////






export default ItemDetails;
