import React,{useRef} from 'react';
import './styles/Opinions.css';
import StarIcon from '@material-ui/icons/Star';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


const Opinions = () => {
    const scrollRef=useRef(null);


    return (
        <div className="opinion-main">
            <div style={{width:'100%',height:'1px',backgroundColor:'grey'}}/>
            <h1>What people think about us?</h1>

            <div style={{display:'flex',alignItems:'center',flex:1,}}>

                <ArrowBackIcon onClick={()=>{scrollRef.current.scrollBy({left:-200,top:0,behavior: 'smooth'})}}
                               style={{cursor:'pointer',color:'white',backgroundColor:'rgba(0,0,0,0.6)',borderRadius:'50%',padding:5}}/>
                <div ref={scrollRef} className='opinion-scroller'>

                    <div className="opinions-commentContainer">
                        <div style={{display:'flex'}}>
                            <img className='opinions-image' src={"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"}/>
                            <h3>Aditya Arun</h3>
                        </div>
                        <p>Hired this company to replace my roof and rebuild my chimney. They were very professional and respectful. I plan on using them again for future services. Thank you Omega!</p>
                        <div style={{display:'flex',alignItems:'center'}}><span style={{fontWeight:'bold',padding:5}}>Experience:</span>
                            {[...Array(5)].map((e, i) => <StarIcon style={{color:'#F1C40F'}}/>)}
                        </div>
                    </div>

                    <div className="opinions-commentContainer">
                        <div style={{display:'flex'}}>
                            <img className='opinions-image' src={"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"}/>
                            <h3>Matt Silva</h3>
                        </div>
                        <p>Great company to work with!
                            Very professional guys, came to my house gave me a very fair price to repair my roof leak.
                            I would recommend them to anyone.
                            Thank you Manny and Barry!</p>
                        <div style={{display:'flex',alignItems:'center'}}><span style={{fontWeight:'bold',padding:5}}>Experience:</span>
                            {[...Array(5)].map((e, i) => <StarIcon style={{color:'#F1C40F'}}/>)}
                        </div>
                    </div>

                    <div className="opinions-commentContainer">
                        <div style={{display:'flex'}}>
                            <img className='opinions-image' src={"https://firebasestorage.googleapis.com/v0/b/omegaquality-official.appspot.com/o/clients%2Fclient4.png?alt=media&token=57b90106-7c1e-4567-8b0a-9b49f8f5882b"}/>
                            <h3>Letizia Young</h3>
                        </div>
                        <p>They did a great job fixing my roof in a record time</p>
                        <div style={{display:'flex',alignItems:'center'}}><span style={{fontWeight:'bold',padding:5}}>Experience:</span>
                            {[...Array(5)].map((e, i) => <StarIcon style={{color:'#F1C40F'}}/>)}
                        </div>
                    </div>


                    <div className="opinions-commentContainer">
                        <div style={{display:'flex'}}>
                            <img className='opinions-image' src={"https://firebasestorage.googleapis.com/v0/b/omegaquality-official.appspot.com/o/clients%2Fclient3.png?alt=media&token=347ffaf9-b1fe-414f-8cf1-9eeec28285f6"}/>
                            <h3>Andy Lond</h3>
                        </div>
                        <p>They do a great work, I can say that I am happy with their work</p>
                        <div style={{display:'flex',alignItems:'center'}}><span style={{fontWeight:'bold',padding:5}}>Experience:</span>
                            {[...Array(4)].map((e, i) => <StarIcon style={{color:'#F1C40F'}}/>)}
                        </div>
                    </div>


                    <div className="opinions-commentContainer">
                        <div style={{display:'flex'}}>
                            <img className='opinions-image' src={"https://firebasestorage.googleapis.com/v0/b/omegaquality-official.appspot.com/o/clients%2Fclient1.png?alt=media&token=74e2800a-df6d-4d1d-b564-b5f7ea07af98"}/>
                            <h3>Margareth Hill</h3>
                        </div>
                        <p>Omega did a great job by repairing my chimney.</p>
                        <div style={{display:'flex',alignItems:'center'}}><span style={{fontWeight:'bold',padding:5}}>Experience:</span>
                            {[...Array(5)].map((e, i) => <StarIcon style={{color:'#F1C40F'}}/>)}
                        </div>
                    </div>

                </div>
                <ArrowForwardIcon onClick={()=>{scrollRef.current.scrollBy({left:200,top:0,behavior: 'smooth'})}}
                                  style={{color:'white',cursor:'pointer',backgroundColor:'rgba(0,0,0,0.6)',borderRadius:'50%',padding:5}}/>

            </div>

        </div>
    )
}

export default Opinions;
