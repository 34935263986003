import React from 'react';
import './styles/AboutUs.css';
import {Button} from '@material-ui/core';
import {Link} from 'react-router-dom';

function AboutUs({myRef}) {
    return (
        <div ref={myRef} className='aboutus-main'>
            <div className='aboutus-main_page'>
            <h1 className="aboutus-aboutus_text">About Us</h1>
            <img className='aboutus-image' src={"https://images.unsplash.com/photo-1544359036-6186e7b1ad84?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"}/>
            <div className="aboutus-desc_container">
            <h4 >Omega Quality Inc is a Long Island based Roofing,
                Skylight and Siding installation company with over 10 years
                of experience in roofing, skylight, siding and gutters installation
                and repairing services. Omega Quality provide professional,
                high-quality and dependable services for residential and commercial buildings.
                When you are in a need of new roof, roof repair, roof installation, skylight installation,
                repair or gutter cleaning, trust the company that has been satisfying the customers from last
                10 years. We are fully licensed and insured.</h4>
                <Link to='/about' style={{textDecoration:'none',color:'black'}}>
            <Button style={{padding:"5px 20px",backgroundColor:'#C0392B',fontWeight: 'bold'}} className="aboutus-readmore_btn">
                Read More
            </Button>
            </Link>
            </div>
            </div>
        </div>
    )
}

export default AboutUs;
