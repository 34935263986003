import './App.css';
import Head from './components/Head';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import CompServices from './components/CompServices';
import OurLocation from './components/OurLocation';
import OurProjects from './components/OurProjects';
import AboutUsPage from './components/AboutUsPage';
import ItemDetails from './components/ItemDetails';
import ScrollToTop from './components/ScrollToTop';
import OurWork from './components/OurWork';
import { useRef, useEffect, useState} from 'react';

import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import Opinions from './components/Opinions';


function App() {


  const aboutus =useRef(null);
  const services =useRef(null);
  const ourproject =useRef(null);
  const contactus =useRef(null);
  const ourlocation =useRef(null);



  const scrollTo=(selected)=>{
      switch(selected){
        case 'aboutus':
          aboutus.current.scrollIntoView({behavior:'smooth'});
          break;
        case 'services':
          services.current.scrollIntoView({behavior:'smooth'});
          break;
        case 'ourproject':
          ourproject.current.scrollIntoView({behavior:'smooth'});
          break;
        case 'contactus':
          contactus.current.scrollIntoView({behavior:'smooth'});
          break;
        case 'ourlocation':
          ourlocation.current.scrollIntoView({behavior:'smooth'});
          break;
      }
  }


  return (
    <Router>
    <ScrollToTop >
    <Route exact path="/item-details/:id">
    <ItemDetails/>
    </Route>
    <Route exact path="/">
    <Head scrollTo={scrollTo}/>
    <AboutUs myRef={aboutus}/>
    <Services myRef={services}/>
    <CompServices/>
    <OurProjects myRef={ourproject}/>
    <Opinions/>
    <ContactUs myRef={contactus}/>
    <OurLocation myRef={ourlocation}/>
    <Footer/>
    </Route>
    <Route path="/about">
    <AboutUsPage/>
    </Route>
    <Route path="/ourwork">
    <OurWork/>
    </Route>
    </ScrollToTop>
    </Router>
  );
}

export default App;
