import React from 'react';
import './styles/CompServices.css';
import roofing from '../images/roof_repair.png';
import flatroofing from '../images/flat_roofing_icon.png';
import chimney from '../images/chimney_repair.png';
import gutter from '../images/gutter_repair.png';
import masonry from '../images/masonry_icon.png';
import siding from '../images/siding_repair.png';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import service24h from '../images/service24h.png';
import affordable from '../images/affordable.png';

const CompServices = () => {
    return (
        <div style={{backgroundColor: "#ECF0F1"}}>
           <h1 style={{textAlign:'center',padding:'50px 0'}}>Complete List <span style={{color:'#084871'}}>Of Services</span></h1>
           <div className="cservices-all_container">
            
            <div className="cservices-lists">
                <img src={roofing} />
                <h3>Roofing</h3>
                <div className="thebg">
                <ul className="cservices-list_services">
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Roof Repair</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Emergency Roof Repair</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Roof Leak Repair</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Roof Flashing</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Roof Replacement</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Roof Installation</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Roof Framing</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Skylight Repair</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Shingle Roofing</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Cedar Shake Roofing</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Metal Roofing</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Tile Roofing</p></li>
                </ul>
                </div>
            </div>

            <div className="cservices-lists">
                <img src={flatroofing} />
                <h3>Flat Roofing</h3>
                <div className="thebg">
                <ul className="cservices-list_services">
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Flat Roof Repair</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Flat Roof Leak Roof Repair</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Flat Roof Installation</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Flat Roof Replacement</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Torch Down Roofing</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Rubber Roofing</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>PVC Roofing</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>TPO Roofing</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Roll Roofing</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Peel and Stick Roofing</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>GACORoof Coating</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Roof Waterproofing</p></li>
                </ul>
                </div>
            </div>

            <div className="cservices-lists">
                <img src={chimney} />
                <h3>Chimney Repairment</h3>
                <div className="thebg">
                <ul className="cservices-list_services">
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Chimney Cleaning</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Chimney Repair</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Chimney Flashing</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Chimney Liners</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Chimney Construction</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Chimney Repointing</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Chimney Restoration</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Chimney Caps</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Chimney Crown Repair</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Chimney Mortar Repair</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Chimney Brick Repair</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Chimney Leak Repair</p></li>
                </ul>
                </div>
            </div>

            <div className="cservices-lists">
                <img src={gutter} />
                <h3>Gutters</h3>
                <div className="thebg">
                <ul className="cservices-list_services">
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Gutter Cleaning</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Gutter Maintenance</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Gutter Repair</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Gutter Leak Repair</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Gutter Replacement</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Gutter Installation</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Seamless Gutters</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Copper Gutters</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Aluminum Gutters</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Industrial Gutters</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Downspout Cleaning</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Downspout Repair</p></li>
                </ul>
                </div>
            </div>

            <div className="cservices-lists">
                <img src={masonry} />
                <h3>Masonry</h3>
                <div className="thebg">
                <ul className="cservices-list_services">
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Masonry Repair</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Brick Repair</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Stone Work</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Sidewalk Repair</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Brick Step Repair</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Stone Step Repair</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Stoop Repair</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Concrete Step Repair</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Chimney Rebuild</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Retaining Walls</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Custom Fireplace</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Fireplace Repairr</p></li>
                </ul>
                </div>
            </div>

            <div className="cservices-lists">
                <img src={siding} />
                <h3>Siding</h3>
                <div className="thebg">
                <ul className="cservices-list_services">
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Siding Repair</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Siding Replacement</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Siding Installation</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Vinyl Siding</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Wood Siding</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Scallop Siding</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Stone Siding</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Brick Siding</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Shingle Siding</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Cedar Shake Siding</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Cedar Shake Impression</p></li>
                    <li><p className="cservices-item"><NavigateNextIcon style={{fontSize:'20px'}}/>Composite Siding</p></li>
                </ul>
                </div>
            </div>

           </div>

           <div className="cservices-desc_container" style={{display:'flex',justifyContent:'center',margin:'70px'}}>
           <div className='cservices-desc'>
               <img src={service24h}/>
               <h2 style={{color:' #F1C40F'}}>24/7 availability</h2>
               <p>Whеnever you can call us, wе аrе соmmіttеd tо customer satisfaction wіth quality аrtіѕtrу</p>
           </div>

           <div className='cservices-desc'>
               <img src={affordable}/>
               <h2 style={{color:' #F1C40F'}}>Affordable prices</h2>
               <p>We will hеlр fіx a leak before it bесоmеѕ a disaster. We саn hеlр уоu mаkе thе nесеѕѕаrу rераіr аll wіthіn your budgеt.</p>
           </div>

           </div>
           
           <div style={{width:'100%',height:'1px',backgroundColor:'grey',marginTop:'30px'}}/>
        </div>
    )
}

export default CompServices;
