import React,{useState,useEffect,useRef} from 'react';
import './styles/Head.css';
import HeadContact from './HeadContact';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import logo from '../images/logo.png';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import CloseIcon from '@material-ui/icons/Close';
import medal1 from '../images/medal-4-1.png';
import medal2 from '../images/medal-4-2.png';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const data=[
    {content:
    <div className="head-item1">
        <h1 style={{textAlign:'center'}}>We Are Avaliable 24/7</h1>
        <h1 style={{textAlign:'center'}}>CALL US FOR FREE ESTIMATE<br></br>
            <div style={{display:'flex',alignItems:'center', width:'100%'}}>
                <h4
                    style={{cursor: 'pointer'}}
                    onClick={()=>window.open("tel:+1-934-852-0025")} className='head-colorized header-social_contact'
                    style={{padding:'0 10px',fontSize:'30px', color:'#E67E22', width:'100%'}}>+1 (934) 852-0025

                </h4>
            </div>

            <span className='discount' style={{width:'100%'}}>25% discount!!!</span>

        </h1>

    </div>,
    image:"https://images.unsplash.com/photo-1503387762-592deb58ef4e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1489&q=80"},
    {content: <div className="head-item1">
    <h1 className="head-item2" style={{textAlign:'center'}}>We Are Expert In Fixing Roofing Problems</h1>
    </div>,
    image:"https://images.unsplash.com/photo-1482731215275-a1f151646268?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"},
    {content:
        <div className="head-item1">
            <h1 style={{textAlign:'center'}}>Expertise You Can Trust</h1>
            <div style={{display:'flex'}}>
                <img className="head-image_medal" src={medal1}/>
                <img className="head-image_medal" src={medal2}/>
            </div>
        </div>,
    image:"https://images.unsplash.com/photo-1512331327491-d03cb97d92b4?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1050&q=80"},
    {content:
        <div className="head-item1">
            <h1 className="we-are-available">We Are Avaliable <br/> For Any Problems</h1>
            <div className="head-grid">
  <div className="head-col"><NavigateNextIcon style={{fontSize:'20px'}}/>Chimney Repair</div>
  <div className="head-col"><NavigateNextIcon style={{fontSize:'20px'}}/>Leaking Problems</div>
  <div className="head-col"><NavigateNextIcon style={{fontSize:'20px'}}/>Cleaning</div>
  <div className="head-col"><NavigateNextIcon style={{fontSize:'20px'}}/>Inspection</div>
  <div className="head-col"><NavigateNextIcon style={{fontSize:'20px'}}/>Renovation</div>
</div>
        </div>,
    image:"https://images.unsplash.com/photo-1604246400392-45a7d3bc1c4b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"},
]


function Head({scrollTo}) {
    const [current,setCurrent]=useState(0);
    const [isActive,setIsActive]=useState(false);



    const moveNext=()=>{
       setCurrent(current=>(current+1)%(data.length));
    }

    const movePrev=()=>{
        setCurrent(current=> current===0 ? (data.length-1) : current-1);
    }


    const getNext=(indx)=>{
        return (indx+1)%(data.length);
     }

     const getPrev=(indx)=>{
        return indx===0 ? (data.length-1) : indx-1;
     }


    function MenuIcon(){
        if(isActive){
            return <CloseIcon onClick={()=>setIsActive(isActive=>!isActive)} style={{fontSize:'50px',cursor:'pointer'}}/>;
        }else{
            return  <MenuOpenIcon onClick={()=>setIsActive(isActive=>!isActive)} style={{fontSize:'50px',cursor:'pointer'}}/>
        }
    }



    return (
        <div className={isActive?'head-main mainActive':'head-main'}>

            <HeadContact/>

            <div className='head-menu-container'>
            <div style={{display:'flex',alignItems:'center'}}>
            <img src={logo} className="header-logo_omega"/>
            <h1 className="header-title_omega">OMEGA QUALITY INC.</h1>
            </div>

            <div className="header-menu_icon">
           {MenuIcon()}
            <h1 style={{marginLeft:'15px'}}>Menu</h1>
            </div>
            <nav
            className={isActive?"header-navbar isActive":"header-navbar"}>
           <ul
           className={isActive?"header-menu_main isActive":"header-menu_main"}>
               <li onClick={()=>scrollTo('aboutus')} className='head-menu'><p>About Us</p></li>
               <li onClick={()=>scrollTo('services')} className='head-menu'><p>Services</p></li>
               <li onClick={()=>scrollTo('ourproject')} className='head-menu'><p>Our Works</p></li>
               <li onClick={()=>scrollTo('ourlocation')} className='head-menu'><p>Areas We Service</p></li>
               <li onClick={()=>scrollTo('contactus')} className='head-menu'><p>Contact Us</p></li>
           </ul>
           </nav>
            </div>

            <div>
            <ArrowForwardIcon style={{fontSize:'40px'}} onClick={moveNext} className={isActive?"header-arrow right iconActive":"header-arrow right"}/>
            <ArrowBackIcon style={{fontSize:'40px'}} onClick={movePrev} className={isActive?"header-arrow iconActive":"header-arrow"}/>
            {data.map((item,index)=>{
               return(
                <div
                className={ current===index?"header-slider active":
                current===getNext(index)?"header-slider next":
                current===getPrev(index)?"header-slider prev":"header-slider"}>
                {current===index &&
                <div>
                    <div className="head-theopacity"/>
                   <img src={item['image']} className="head-image_slider"/>
                   {item.content}
                </div>
                }
                </div>
               );
            })}
            </div>
        </div>
    )
}

export default Head;
